@font-face {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "Morebi";
  font-style: normal;
  font-weight: normal;
  src: local("Morebi"),
    url("./assets/fonts/Morebi.otf") format("opentype");
}

@font-face {
  font-family: "MorebiBlack";
  font-style: normal;
  font-weight: 500;
  src: local("MorebiBlack"),
    url("./assets/fonts/MorebiBlack.otf") format("opentype");
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}
.note-editor.note-frame .note-editing-area .note-editable b {
  font-weight: bold !important;
}

body[data-layout-mode="two-column"]
  .sidebar-main-menu
  .nav
  > .nav-item
  > .nav-link {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  font-size: 0.85rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-control {
  position: unset;
}

.icon-sidebar {
  color: #ffffff;
  font-size: 1.55em;
  line-height: unset;
}

body[data-layout-mode="two-column"] .sidebar-icon-menu {
  background-color: #35b0a7;
}

body[data-layout-mode="two-column"] .sidebar-main-menu {
  padding: 20px 5px 30px;
}

.table .thead-light th {
  background-color: #f5f6f8;
}

.table td {
  padding: 0.75em;
}

.nav-bordered a.active {
  border-bottom: 2px solid #35b0a7 !important;
}

/** summernote override css */
.note-editing-area .note-editable[contenteditable="false"] {
  background-color: #f8f8f8 !important;
  color: #6c757d !important;
}
.note-editing-area .note-editable[contenteditable="true"] {
  color: #000001 !important;
}

body[data-layout-mode="two-column"] .left-side-menu {
  z-index: 1;
}

@media (min-width: 768px) {
  body[data-sidebar-size="condensed"]:not([data-layout="compact"]) {
    min-height: 100vh;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  /* size of the QR, printed on A3 paper */
  @page {
    size: 70mm 99mm;
    margin: 0;
  }
  .page-break-after {
    break-after: page;
  }
}
