.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  font-weight: normal;
}

.radio label {
  font-weight: normal;
}

.accordion > .card {
  overflow: unset;
}

.page-link {
  position: unset;
}

.mw-250 {
  max-width: 250px;
}

.mw-300 {
  max-width: 300px;
  word-wrap: break-word;
}

.mw-350 {
  max-width: 350px;
  word-wrap: break-word;
}

.mw-200 {
  min-width: 200px;
  max-width: 200px;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.secondary-data-panel {
  position: relative;

  width: 1000px;

  white-space: normal;
}

.secondary-data-panel-mobile {
  position: relative;

  width: 250px;

  white-space: normal;
}

.flatpickr-mobile {
  width: 100%;
  padding: 6px 5px 7px 5px;
  border: 1px solid #ccc;
}

.input-group-append {
  z-index: 0;
}

body[data-layout-mode="two-column"]
  .sidebar-icon-menu
  .nav
  .nav-link {
  width: 100%;
  height: 60px;
  margin: 3px auto;
}

.link-rukita:hover {
  color: #1abc9c !important;
  text-shadow: 1px 0;
}

.link-rukita-v2 {
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #00bbb4;
  outline: none;
}

.link-rukita-v2:hover {
  color: #009c96;
  text-decoration: underline;
}

.link-rukita-v2:focus {
  box-shadow: 0 0 0 4px rgba(0, 187, 180, 0.2);
  color: #009c96;
  text-decoration: underline;
}

.link-rukita-v2:active {
  color: #063735;
  text-decoration: underline;
  box-shadow: unset;
}

.link-rukita-v2:disabled {
  color: rgba(0, 187, 180, 0.2);
  cursor: default;
  pointer-events: none;
}

.dropdown-table {
  background-color: unset;
  border: unset;
  font-size: 1.3rem;
  padding: 0rem 0.4rem;
  color: #999999;
}

a.overlay-navigation,
div.overlay-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

button.overlay-navigation,
span.overlay-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: none;
  border: none;
  outline: none;
}

button.overlay-navigation {
  padding: 0;
  width: 100%;
  height: 100%;
}

.custom-col-thead {
  padding: 8px 6px !important ;
  font-size: 10px;
  white-space: normal !important;
  vertical-align: bottom;
}

.custom-col-td {
  padding: 8px 6px !important;
  font-size: 12px;
  font-weight: 400;
  color: #222;
}

.custom-select {
  padding-right: 12px;
  background: url("./assets/icons/arrow-down.png") no-repeat 55px;
  background-size: 10px 5px;
}
